.top-bar-wrapper {
  width: 100%;
  height: 10vh;
  padding: 0 0.4rem;
  position: relative;
  top: 0;
  left: 0;
  z-index: 100;
  text-align: center;
}
.top-bar-wrapper .top-bar-content {
  height: 88px;
  display: flex;
  justify-content: center;
  position: relative;
}
.top-bar-wrapper .top-bar-content.h-88 {
  height: 88px;
}
.top-bar-wrapper .top-bar-content .top-bar-logo {
  position: absolute;
  left: 0;
  cursor: pointer;
}
.top-bar-wrapper .top-bar-content .top-bar-logo .img-home {
  width: auto;
  height: 0.59rem;
  margin-top: 0.29rem;
}
.top-bar-wrapper .top-bar-content .top-bar-logo .img-to-home {
  margin-top: 0.2rem;
  width: auto;
  height: 0.59rem;
}
.top-bar-wrapper .top-bar-content .menu-center {
  display: inline-block;
  position: absolute;
  left: 50%;
  margin-left: -80px;
  background-position: center bottom;
  transform-style: preserve-3d;
}
.top-bar-wrapper .top-bar-content .menu-center.menu-center-fold {
  width: 180px;
  height: 110px;
  bottom: -28px;
  animation: flipClose 0.4s linear forwards;
}
.top-bar-wrapper .top-bar-content .menu-center.menu-center-fold .img-menu {
  cursor: pointer;
  position: absolute;
  text-align: center;
  left: 50%;
  margin-left: -90px;
  bottom: 4px;
}
.top-bar-wrapper .top-bar-content .menu-center.menu-center-open {
  width: 180px;
  height: 70px;
  overflow: hidden;
  bottom: -54px;
  animation: flipOpen 0.4s linear forwards;
}
.top-bar-wrapper .top-bar-content .menu-center.menu-center-open .img-menu {
  cursor: pointer;
  position: absolute;
  text-align: center;
  left: 50%;
  margin-left: -85px;
  bottom: 8px;
}
.top-bar-wrapper .top-bar-content .top-bar-avatar {
  margin-top: 0.29rem;
  position: absolute;
  right: 0;
  width: 0.7rem;
  height: 0.7rem;
}
.top-bar-wrapper .top-bar-content .top-bar-avatar .img-avatar {
  width: 0.7rem;
  height: 0.7rem;
  background: url("../../assets/new_ui/avatar_header.png") no-repeat;
  background-size: 100% 100%;
}
.top-bar-wrapper .top-bar-content .top-bar-avatar .img-avatar:hover {
  background: url("../../assets/new_ui/avatar_header_hover.png") no-repeat;
  background-size: 100% 100%;
}
.top-bar-wrapper .top-bar-content .top-bar-avatar .img-avatar-login {
  width: 0.7rem;
  height: 0.7rem;
  background: url("../../assets/new_ui/avatar_header_gray.png") no-repeat;
  background-size: 100% 100%;
}
.top-bar-wrapper .top-bar-content .top-bar-avatar .img-avatar-login:hover {
  filter: saturate(125%) brightness(108%);
}
.top-bar-wrapper .top-bar-content .top-menu-box {
  display: inline-block;
  margin-top: 10px;
  margin-right: 5px;
  position: relative;
}
.top-bar-wrapper .top-bar-content .top-menu-box .menu-item {
  display: inline-block;
  opacity: 0;
}
.top-bar-wrapper .top-bar-content .top-menu-box .menu-item:nth-of-type(1) {
  animation: bounceIn 0.5s linear 0.4s forwards;
}
.top-bar-wrapper .top-bar-content .top-menu-box .menu-item:nth-of-type(2) {
  animation: bounceIn 0.5s linear 0.4s forwards;
}
.top-bar-wrapper .top-bar-content .top-menu-box .menu-item:nth-of-type(3) {
  animation: bounceIn 0.5s linear 0.4s forwards;
}
.top-bar-wrapper .top-bar-content .top-menu-box .menu-item:nth-of-type(4) {
  animation: bounceIn 0.5s linear 0.4s forwards;
}
.top-bar-wrapper .top-bar-content .top-menu-box .menu-item:nth-of-type(5) {
  animation: bounceIn 0.5s linear 0.4s forwards;
}
.top-bar-wrapper .top-bar-content .top-menu-box .menu-item + .menu-item {
  margin-left: 0.8rem;
}
.top-bar-wrapper .top-bar-content .top-menu-box .menu-item img {
  width: 1.6rem;
}
.top-bar-wrapper .top-bar-content .top-menu-box .menu-item .img-menu:hover {
  animation-name: tada;
  transform-origin: center bottom;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}
.top-bar-wrapper .left-back-box {
  margin-top: 0.17rem;
  width: 0.7rem;
  height: 0.7rem;
  position: absolute;
  left: 0.4rem;
  top: 0.03rem;
}
.top-bar-wrapper .left-back-box .img-go-back {
  width: 100%;
  height: 100%;
  background: url("../../assets/new_ui/icon-arrow-left.png") no-repeat;
  background-size: 100% 100%;
}
.top-bar-wrapper .left-back-box .img-go-back:hover {
  background: url("../../assets/new_ui/icon-arrow-left-hover.png") no-repeat;
  background-size: 100% 100%;
}
.top-bar-wrapper .top-bar-animate {
  width: 100%;
  height: 0;
}
.top-bar-wrapper .top-bar-open {
  height: 40px;
  animation: headerOpen 0.8s;
}
.top-bar-wrapper .top-bar-fold {
  height: 0px;
  animation: headerClose 0.8s;
}
@keyframes flipOpen {
  0% {
    transform: perspective(400px) rotateX(90deg);
  }
  40% {
    transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    transform: perspective(400px) rotateX(10deg);
  }
  100% {
    transform: perspective(400px) rotateX(0);
  }
}
@keyframes flipClose {
  0% {
    transform: perspective(400px) rotateX(-90deg);
  }
  40% {
    transform: perspective(400px) rotateX(10deg);
  }
  70% {
    transform: perspective(400px) rotateX(-10deg);
  }
  100% {
    transform: perspective(400px) rotateX(0);
  }
}
.el-drawer__wrapper .avatar-drawer {
  overflow: visible;
  width: 3rem !important;
  border-radius: 40px 0 0 40px;
}
.el-drawer__wrapper .avatar-drawer .el-drawer__body {
  overflow: visible;
  position: relative;
}
.el-drawer__wrapper .avatar-drawer .el-drawer__body .close-box {
  display: inline-block;
  width: 0.6rem;
  height: 0.6rem;
  position: absolute;
  left: -45px;
  top: 45%;
  margin-top: -0.14rem;
  cursor: pointer;
}
.el-drawer__wrapper .avatar-drawer .el-drawer__body .close-box .img-close {
  position: absolute;
  left: 6px;
  top: 15px;
}
.el-drawer__wrapper .avatar-drawer .el-drawer__body .close-box:hover .img-close {
  filter: saturate(125%) brightness(108%);
}
.el-drawer__wrapper .avatar-drawer .el-drawer__body .drawer-content {
  position: relative;
  height: 100vh;
  border-radius: 40px 0 0 40px;
}
.el-drawer__wrapper .avatar-drawer .el-drawer__body .drawer-content .drawer-top {
  width: 100%;
  height: 1.4rem;
  line-height: 0.64rem;
  padding: 0.42rem 0px 0.42rem 0.54rem;
  border-radius: 50px 0 0 0;
  border-radius: 40px 0 0 0px;
}
.el-drawer__wrapper .avatar-drawer .el-drawer__body .drawer-content .drawer-top .img-avatar {
  display: inline-block;
  vertical-align: middle;
  width: 0.64rem;
  height: 0.64rem;
}
.el-drawer__wrapper .avatar-drawer .el-drawer__body .drawer-content .drawer-top .span-name {
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.1rem;
}
.el-drawer__wrapper .avatar-drawer .el-drawer__body .drawer-content .drawer-top.drawer-top-login {
  background: #ade787;
}
.el-drawer__wrapper .avatar-drawer .el-drawer__body .drawer-content .drawer-top.drawer-top-login .span-name-login {
  display: inline-block;
  line-height: 0.01rem;
  margin-top: 0.06rem;
}
.el-drawer__wrapper .avatar-drawer .el-drawer__body .drawer-content .drawer-top.drawer-top-login .span-name-login .span-name {
  display: inline-block;
  vertical-align: middle;
  line-height: 0.24rem;
  color: #448816;
  font-size: 0.18rem;
  font-weight: 600;
  text-align: left;
  width: 1.26rem;
  font-family: PingFangSC-Semibold, PingFang SC !important;
}
.el-drawer__wrapper .avatar-drawer .el-drawer__body .drawer-content .drawer-top.drawer-top-login .span-name-login .span-phone {
  display: inline-block;
  vertical-align: middle;
  line-height: 0.24rem;
  color: #448816;
  font-size: 0.15rem;
  text-align: left;
  margin-left: 0.1rem;
  font-family: PingFangSC-Semibold, PingFang SC !important;
}
.el-drawer__wrapper .avatar-drawer .el-drawer__body .drawer-content .drawer-top.drawer-top-not-login {
  background: #f0f0f0;
}
.el-drawer__wrapper .avatar-drawer .el-drawer__body .drawer-content .drawer-top.drawer-top-not-login .span-name {
  color: #737373;
  font-size: 0.16rem;
  font-weight: bold;
}
.el-drawer__wrapper .avatar-drawer .el-drawer__body .drawer-content .drawer-list {
  padding: 0 0.1rem;
  margin-top: 0.16rem;
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  height: 20vh;
}
.el-drawer__wrapper .avatar-drawer .el-drawer__body .drawer-content .drawer-list .drawer-item {
  height: 3vh;
  width: 100%;
  cursor: pointer;
  padding: 0 0.1rem;
  display: flex;
  align-items: center;
}
.el-drawer__wrapper .avatar-drawer .el-drawer__body .drawer-content .drawer-list .drawer-item .img-icon {
  width: 0.34rem;
  height: 0.26rem;
  display: inline-block;
  vertical-align: middle;
}
.el-drawer__wrapper .avatar-drawer .el-drawer__body .drawer-content .drawer-list .drawer-item .span-title {
  display: inline-block;
  vertical-align: middle;
  width: 80%;
  height: 3vh;
  line-height: 3vh;
  font-size: 0.16rem;
  font-weight: bold;
  color: #448816;
  margin-left: 0.15rem;
  border-bottom: 0.01rem solid #f7eadb;
}
.el-drawer__wrapper .avatar-drawer .el-drawer__body .drawer-content .drawer-list .drawer-item:hover .img-icon,
.el-drawer__wrapper .avatar-drawer .el-drawer__body .drawer-content .drawer-list .drawer-item:hover .span-title {
  opacity: 0.8;
}
.el-drawer__wrapper .avatar-drawer .el-drawer__body .drawer-content .drawer-bottom {
  position: absolute;
  bottom: 0;
  left: 0.54rem;
  top: 85vh;
  margin: 0 auto;
  width: 2rem !important;
  height: 0.46rem !important;
  line-height: 0.46rem !important;
  text-align: center;
  font-size: 0.16rem !important;
}
.el-drawer__wrapper .avatar-drawer .el-drawer__body .drawer-content .login-btn {
  width: 200px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  font-size: 16px;
}
.el-drawer__wrapper .avatar-drawer .el-drawer__body .drawer-content .login-btn:hover {
  background: #d0f1b0 !important;
}
.img-menu1 {
  position: relative;
  top: 0.16rem;
  animation: none 0 ease 0 1 normal;
  animation-name: none;
  transform-origin: center bottom;
  animation-duration: 0s;
  animation-fill-mode: none;
}
